import React from "react";

const PageNotFound = () => {
  return (
    <div className="st-page-not-found st-flex-center text-center">
      <div className="">
        <h3>
          4<span>0</span>4
        </h3>
        <h4>Page Not Found</h4>
        <p>oops, the page you are looking for can't be found!</p>
      </div>
    </div>
  );
};

export default PageNotFound;
