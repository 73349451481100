import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import Book from "../../Assets/syncsound/Book.svg";
import AudioPlayer from "./AudioPlayer";
import SonicConsciousness from "../../Assets/files/SonicConsciousness.mp3";

const AudioBook = () => {
  return (
    <section
      id="testimonial"
      className="container"
      style={{
        marginBottom: "100px",
      }}
    >
      <Typography
        mt={["30px", "80px"]}
        textAlign={"center"}
        color={"#162644"}
        fontSize={["20px", "40px"]}
        fontWeight={700}
        fontFamily={"Nunito"}
        lineHeight={"54.56px"}
      >
        Coming soon
      </Typography>
      <Typography
        mt={["15px", "30px"]}
        textAlign={"center"}
        color={"#162644"}
        fontSize={["20px", "40px"]}
        fontWeight={700}
        fontFamily={"Nunito"}
        lineHeight={"54.56px"}
      >
        Audiobook
      </Typography>
      <Typography
        textAlign={["center", "center"]}
        color={"#162644"}
        fontSize={["28px", "48px"]}
        fontWeight={700}
        fontFamily={"Nunito"}
        lineHeight={["30px", "62.4px"]}
        mb={"15px"}
      >
        Sonic Consciousness
      </Typography>
      <Typography
        mt={["10px", "15px"]}
        textAlign={"center"}
        color={"#162644"}
        fontSize={["16px", "20px"]}
        fontWeight={[500, 500]}
        fontFamily={"Nunito"}
        lineHeight={["20px", "30px"]}
      >
        Introduction to a New Model of Holistic Sonic Therapy: <br /> Its
        Foundations, Key Discoveries, Technology, and Philosophy.
      </Typography>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        mt={["20px", "80px"]}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-evenly"}
        >
          <Box>
            <Typography
              mt={"60px"}
              textAlign={["center", "left"]}
              color={"#162644"}
              fontSize={["22px", "36px"]}
              fontWeight={700}
              fontFamily={"Nunito"}
              lineHeight={"50px"}
            >
              Audiobook Preface
            </Typography>
            <Typography
              mt={["10px", "15px"]}
              textAlign={["center", "left"]}
              color={"#162644B2"}
              fontSize={"20px"}
              fontWeight={500}
              fontFamily={"Nunito"}
              lineHeight={"30px"}
            >
              Listen to Preface
            </Typography>
          </Box>
          <Box>
            <AudioPlayer
              audioSrc={SonicConsciousness}
              title={"Sonic Consciousness"}
            />
          </Box>
          <Box textAlign={["center", "left"]} mt={["10px", "10px"]}>
            <Button
              style={{
                backgroundColor: "#162644",
                color: "white",
                padding: "15px 25px 15px 25px",
              }}
            >
              Purchase book
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <img src={Book} alt="book" />
        </Grid>
      </Grid>
      {/* <div className="swiper-part">
        <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
          <SwiperSlide>
            <img src={TestimonialImg} className="image-slider-res" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={TestimonialImg} className="image-slider-res" />
          </SwiperSlide>
        </Swiper>
      </div> */}
    </section>
  );
};

export default AudioBook;
