import React from "react";
import Hero from "../Components/Hero/Hero";
import ContactUs from "../Components/ContactUs/ContactUs";
import Overview from "../Components/Overview/Overview";
import Hows from "../Components/Hows/Hows";
import AudioBook from "../Components/AudioBook/AudioBook";
import Feedback from "../Components/Feedback/Feedback";

const Home = () => {
  return (
    <>
      <Hero />
      <Overview />
      <Hows />
      <AudioBook />
      <Feedback />
      <ContactUs />
      <hr />
    </>
  );
};

export default Home;
