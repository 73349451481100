import React from "react";
import howsBG from "../../Assets/syncsound/howsBG.svg";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Box, Grid, Typography } from "@mui/material";
import AudioPlayer from "../AudioBook/AudioPlayer";
import SyncBySonicAudioTutorial from "../../Assets/files/SyncBySonic Audio Tutorial.mp3";

const Hows = () => {
  return (
    <section
      id="gallery"
      style={{ background: `url(${howsBG})`, backgroundSize: "cover" }}
    >
      <div className="container">
        <Typography
          textAlign={["center", "center"]}
          color={"#162644"}
          fontSize={["28px", "48px"]}
          fontWeight={700}
          fontFamily={"Nunito"}
          lineHeight={["30px", "62.4px"]}
          pt={"75px"}
          pb={"20px"}
        >
          How it works
        </Typography>
        <Typography
          mt={"10px"}
          textAlign={"center"}
          color={"#162644"}
          fontSize={["16px", "20px"]}
          fontWeight={700}
          fontFamily={"Nunito"}
          lineHeight={["20px", "30px"]}
        >
          Within the application, you’ll be guided step-by-step to craft your
          own sonic therapy session, <br /> uniquely tailored to address your
          specific pain add discomfort
        </Typography>
      </div>
      <div className="container">
        <div className="st-portfolio-wrapper">
          <Box display={"flex"} mt={"80px"}>
            <Box m={["6px 10px", "15px 20px"]}>
              <FiberManualRecordIcon />
            </Box>
            <Box>
              <Typography
                color={"#162644"}
                fontSize={["24px", "36px"]}
                fontWeight={700}
                fontFamily={"Nunito"}
                lineHeight={["30px", "62.4px"]}
              >
                Humming: the core of SyncBySonic.
              </Typography>
              <Box>
                <Box borderLeft={"5px solid blue"}></Box>
                <Typography
                  mt={"10px"}
                  color={"#162644"}
                  fontSize={["16px", "20px"]}
                  fontWeight={500}
                  fontFamily={"Nunito"}
                  lineHeight={["20px", "27.28px"]}
                >
                  Your own humming sound is a critical element in this
                  revolutionary form of therapy. Humming, widely recognized for
                  its various benefits by scientific communities, serves a
                  unique role in this application. Specifically, the sound
                  created by your humming is a source of information about your
                  current state of discomfort or pain as your voice reflects
                  your inner state and physiology. This information is recorded
                  and processed through the SyncBySonic algorithm, which then
                  converts it into a tone. This serves as the starting point for
                  creating your personalized therapy session.
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box display={"flex"} mt={"60px"}>
            <Box m={["6px 10px", "15px 20px"]}>
              <FiberManualRecordIcon />
            </Box>
            <Box>
              <Typography
                color={"#162644"}
                fontSize={["24px", "36px"]}
                fontWeight={700}
                fontFamily={"Nunito"}
                lineHeight={["30px", "62.4px"]}
              >
                Creating auditory beat stimulation.
              </Typography>
              <Box>
                <Box borderLeft={"5px solid blue"}></Box>
                <Typography
                  mt={"10px"}
                  color={"#162644"}
                  fontSize={["16px", "20px"]}
                  fontWeight={500}
                  fontFamily={"Nunito"}
                  lineHeight={["20px", "27.28px"]}
                >
                  The next step in this process involves you actively
                  participating by humming to identify the monaural beat that
                  alleviates your pain. Monaural beats, which you can actually
                  hear, are generated from the frequencies of your voice. These
                  beats engage the auditory system and offer a unique avenue for
                  targeted pain relief. Once you've identified the effective
                  monaural beat through your own humming, SyncBySonic uses this
                  user-specific data to transform it into personalized binaural
                  beats. While monaural beats are audible and originate from
                  your humming, binaural beats are not directly audible. These
                  binaural beats aim to further align the brain's natural
                  oscillations through a process known as brainwave entrainment,
                  thereby altering your perception for more effective pain
                  management. The result is a personalized sonic therapy
                  session, complete with layered white noise, designed to
                  maximize your comfort and relaxation.
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box display={"flex"} mt={"60px"}>
            <Box m={["6px 10px", "15px 20px"]}>
              <FiberManualRecordIcon />
            </Box>
            <Box>
              <Typography
                color={"#162644"}
                fontSize={["24px", "36px"]}
                fontWeight={700}
                fontFamily={"Nunito"}
                lineHeight={["30px", "62.4px"]}
              >
                Maximizing the Experience:
              </Typography>
              <Grid
                mt={"10px"}
                container
                gap={2}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Grid xs={12} sm={12} md={12} lg={5}>
                  <AudioPlayer
                    audioSrc={SyncBySonicAudioTutorial}
                    title={"SyncBySonic Audio Tutorial"}
                  />
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={6}>
                  <Typography
                    mt={"10px"}
                    color={"#162644"}
                    fontSize={["16px", "20px"]}
                    fontWeight={500}
                    fontFamily={"Nunito"}
                    lineHeight={["20px", "27.28px"]}
                  >
                    To ensure you get the most authentic and effective session
                    possible, we recommend starting with our in-app audio
                    tutorial. This tutorial provides comprehensive instructions
                    and guidance on therapeutic breathing techniques, enhancing
                    the overall therapeutic experience.
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box display={"flex"} mt={"60px"}>
            <Box m={["6px 10px", "15px 20px"]}>
              <FiberManualRecordIcon />
            </Box>
            <Box>
              <Typography
                color={"#162644"}
                fontSize={["24px", "36px"]}
                fontWeight={700}
                fontFamily={"Nunito"}
                lineHeight={["30px", "62.4px"]}
              >
                Be a pioneer in sonic therapy.
              </Typography>
              <Box>
                <Box borderLeft={"5px solid blue"}></Box>
                <Typography
                  mt={"10px"}
                  color={"#162644"}
                  fontSize={["16px", "20px"]}
                  fontWeight={500}
                  fontFamily={"Nunito"}
                  lineHeight={["20px", "27.28px"]}
                >
                  By joining SyncBySonic, you are part of an ambitious attempt
                  to map out the frequencies that can bring relief and
                  tranquility. This initial release serves as a doorway into the
                  future of sonic therapy. Your participation not only
                  contributes to your personal wellness but also helps build a
                  foundation for scientific discoveries that could benefit
                  everyone.
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box display={"flex"} mt={"60px"}>
            <Box m={["6px 10px", "15px 20px"]}>
              <FiberManualRecordIcon />
            </Box>
            <Box>
              <Typography
                color={"#162644"}
                fontSize={["24px", "36px"]}
                fontWeight={700}
                fontFamily={"Nunito"}
                lineHeight={["30px", "62.4px"]}
              >
                Your involvement.
              </Typography>
              <Box>
                <Box borderLeft={"5px solid blue"}></Box>
                <Typography
                  mt={"10px"}
                  color={"#162644"}
                  fontSize={["16px", "20px"]}
                  fontWeight={500}
                  fontFamily={"Nunito"}
                  lineHeight={["20px", "27.28px"]}
                >
                  The more users engage with the platform, the closer we get to
                  identifying patterns for effective pain relief and relaxation.
                  These patterns, once studied and validated, will not only
                  advance our understanding but also enrich the app with new
                  features. Your involvement today directly influences the
                  groundbreaking therapies we can offer tomorrow—therapies that
                  will be freely accessible and continually refined based on
                  collective insights.
                </Typography>
              </Box>
            </Box>
          </Box>
        </div>
      </div>
      <div className="st-height-b120 st-height-lg-b80" />
    </section>
  );
};

export default Hows;
