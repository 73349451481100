import React, { useState, useEffect } from "react";
import { Link as ScrollLink } from "react-scroll";
import logo from "../../Assets/syncsound/Logo.svg";

const Header = () => {
  const [mobileToggle, setMobileToggle] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [isSticky, setIsSticky] = useState(false);

  const handleMobileToggle = () => {
    setMobileToggle(!mobileToggle);
  };

  useEffect(() => {
    const handleScroll = () => {
      const windowTop = window.scrollY || document.documentElement.scrollTop;

      if (windowTop >= headerHeight) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }

      setLastScrollTop(windowTop);
    };

    const headerHeight =
      document.querySelector(".st-sticky-header").offsetHeight + 100;

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isSticky, lastScrollTop]);

  return (
    <header
      className={`st-site-header st-style1 st-sticky-header`}
      style={{ position: "sticky", zIndex: 99 }}
    >
      <div className="st-main-header">
        <div className="container">
          <div className="st-main-header-in">
            <div className="st-main-header-left">
              <ScrollLink
                to="home"
                spy={true}
                duration={500}
                onClick={() => setMobileToggle(false)}
              >
                <img src={logo} alt={logo} />
              </ScrollLink>
            </div>
            <div className="st-main-header-right">
              <div className="st-nav">
                <ul
                  className={`st-nav-list ${mobileToggle ? "d-block" : "none"}`}
                >
                  <li>
                    <ScrollLink
                      to="overview"
                      spy={true}
                      duration={500}
                      offset={-80}
                      onClick={() => setMobileToggle(false)}
                    >
                      SyncBySonic Overview
                    </ScrollLink>
                  </li>
                  <li>
                    <ScrollLink
                      to="gallery"
                      spy={true}
                      duration={500}
                      offset={-80}
                      onClick={() => setMobileToggle(false)}
                    >
                      How it works
                    </ScrollLink>
                  </li>
                  <li>
                    <ScrollLink
                      to="testimonial"
                      spy={true}
                      duration={500}
                      offset={-80}
                      onClick={() => setMobileToggle(false)}
                    >
                      Audiobook
                    </ScrollLink>
                  </li>
                  <li>
                    <ScrollLink
                      to="faqs"
                      spy={true}
                      duration={500}
                      offset={-80}
                      onClick={() => setMobileToggle(false)}
                    >
                      Feedback
                    </ScrollLink>
                  </li>
                  <li>
                    <ScrollLink
                      to="contactus"
                      spy={true}
                      duration={500}
                      offset={-80}
                      onClick={() => setMobileToggle(false)}
                    >
                      Contact Us
                    </ScrollLink>
                  </li>
                </ul>
                <div
                  className={`st-munu-toggle ${
                    mobileToggle ? "st-toggle-active" : ""
                  } `}
                  onClick={handleMobileToggle}
                >
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
