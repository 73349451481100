import { Box, Typography } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import FastForwardIcon from "@mui/icons-material/FastForward";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import FastRewindIcon from "@mui/icons-material/FastRewind";
import PauseIcon from "@mui/icons-material/Pause";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";

import MusicLogo from "../../Assets/syncsound/MusicLogo.svg";

const AudioPlayer = ({ audioSrc, title }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [volume, setVolume] = useState(0.5);

  const audioRef = useRef(null);

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
  };

  const skip = (amount) => {
    audioRef.current.currentTime += amount;
  };

  const handleVolumeChange = (e) => {
    const volume = e.target.value;
    setVolume(volume);
    audioRef.current.volume = volume;
  };

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  }, [isPlaying]);
  console.log(audioRef, "audioRef");

  return (
    <Box className="audio-player" padding={["10px", "30px"]}>
      <Box display={"flex"} m={"5px"}>
        <img src={MusicLogo} style={{ height: "45px" }} />
        <Typography
          fontSize={["12px", "20px"]}
          fontWeight={700}
          margin={"0 20px"}
        >
          {title}
        </Typography>
      </Box>
      <Box display={["block", "flex"]} m={"15px 0 0  0"} alignItems={"center"}>
        <FastRewindIcon className="player_action" onClick={() => skip(-5)} />
        {isPlaying ? (
          <PauseIcon className="player_action" onClick={togglePlay} />
        ) : (
          <PlayArrowIcon className="player_action" onClick={togglePlay} />
        )}
        <FastForwardIcon className="player_action" onClick={() => skip(5)} />{" "}
        <input
          className="audio_range"
          type="range"
          min={0}
          max={audioRef.current ? audioRef.current.duration : 0}
          value={currentTime}
          onChange={(e) => {
            setCurrentTime(e.target.value);
            audioRef.current.currentTime = e.target.value;
          }}
        />{" "}
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          {audioRef?.current ? (
            <Box display={"flex"} alignItems={"center"}>
              <div className="current-time">{formatTime(currentTime)}</div>/
              <div className="current-time">
                {formatTime(audioRef?.current?.duration)}
              </div>
            </Box>
          ) : null}
          <Box display={"flex"} alignItems={"center"}>
            <VolumeUpIcon className="player_action" />
            <input
              className="audio_range"
              style={{ width: "70px" }}
              type="range"
              min={0}
              max={1}
              step={0.01}
              value={volume}
              onChange={handleVolumeChange}
            />
          </Box>
        </Box>
      </Box>
      <audio
        src={audioSrc}
        ref={audioRef}
        onTimeUpdate={handleTimeUpdate}
        onEnded={() => setIsPlaying(false)}
      ></audio>
      {/* <div className="controls">
        <button onClick={() => skip(-5)}>Skip Back 5s</button>
        <button onClick={togglePlay}>{isPlaying ? "Pause" : "Play"}</button>
        <button onClick={() => skip(5)}>Skip Forward 5s</button>
      </div> */}
      {/* <div className="timeline">
        <div className="current-time">{formatTime(currentTime)}</div>
        <input
          type="range"
          min={0}
          max={audioRef.current ? audioRef.current.duration : 0}
          value={currentTime}
          onChange={(e) => {
            setCurrentTime(e.target.value);
            audioRef.current.currentTime = e.target.value;
          }}
        />
        <div className="total-time">
          {formatTime(audioRef.current ? audioRef.current.duration : 0)}
        </div>
      </div> */}
      {/* <div className="volume">
        <input
          type="range"
          min={0}
          max={1}
          step={0.01}
          value={volume}
          onChange={handleVolumeChange}
        />
        <i className="fas fa-volume-up"></i>
      </div> */}
    </Box>
  );
};

export default AudioPlayer;
