import React, { useState } from "react";
import Feature1 from "../../Assets/syncsound/Mobile.svg";
import { Box, Link, Typography } from "@mui/material";
import PDFModal from "../Footer/PDFModal";
import SyncBySonicSoundHomeopathybyEvanSoundwell from "../../Assets/files/SyncBySonic.pdf";

const Overview = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [file, setFile] = useState(null);

  const onCloseModal = () => {
    setIsOpen(false);
  };
  const onOpenModal = (file) => {
    setFile(file);
    setIsOpen(true);
  };
  return (
    <section id="overview" className="container">
      <div className="st-height-b120 st-height-lg-b80" />
      <Typography
        textAlign={["center", "center"]}
        color={"#162644"}
        fontSize={["28px", "48px"]}
        fontWeight={700}
        fontFamily={"Nunito"}
        lineHeight={["30px", "62.4px"]}
        mb={"15px"}
      >
        Beyond Sound: SyncBySonic and the <br /> Future of Personalized Pain
        Therapy.
      </Typography>
      <Typography
        mt={["20px", "60px"]}
        textAlign={"center"}
        color={"#162644"}
        fontSize={["22px", "40px"]}
        fontWeight={400}
        fontFamily={"Nunito"}
        lineHeight={"54.56px"}
      >
        Evan Soundwell
      </Typography>
      <Typography
        mt={"10px"}
        textAlign={"center"}
        color={"#162644"}
        fontSize={["16px", "20px"]}
        fontWeight={700}
        fontFamily={"Nunito"}
        lineHeight={["20px", "30px"]}
      >
        Inventor of SyncBySonic <br /> Founder of Holistic Sonic Therapy FRMT
      </Typography>
      <Box mt={["30px", "120px"]} className="">
        <div className="st-tabs st-fade-tabs st-style1">
          <div className="st-imagebox st-style2">
            <div className="row">
              <div className="col-lg-6">
                <div className="st-imagebox-img">
                  <img src={Feature1} alt="feature-image" />
                </div>

                <div className="st-height-b0 st-height-lg-b30" />
              </div>
              <div className="col-lg-6">
                <div className="st-vertical-middle">
                  <div className="st-vertical-middle-in">
                    <div className="st-imagebox-info">
                      <h2 className="st-imagebox-title">Introduction</h2>
                      <div className="st-imagebox-text">
                        Pain is an intensely subjective and intricate experience
                        shaped by a myriad of variables, ranging from genetics
                        and past experiences to psychological states and
                        cultural backgrounds. Fundamental to these unique
                        experiences are individualized&nbsp;
                        <Link>sets of brainwaves,</Link> which are pivotal to
                        mental and physiological processes. Recent research
                        suggests that oscillations at different frequencies in
                        the brain play a role in the processing of pain.
                        Neuronal oscillations and synchrony at various
                        frequencies have been observed during the experience of
                        pain, ranging from infra-slow fluctuations to theta,
                        alpha, beta, and gamma oscillations.
                      </div>
                      <div className="st-imagebox-text">
                        Brain entrainment techniques, particularly those using
                        monaural and binaural beats, have been floated as
                        potential alternative therapies for a variety of
                        conditions, pain relief being one of them. Despite this
                        promise, the practical application of these techniques
                        in pain management faces several substantial hurdles.
                        Traditional approaches to brainwave entrainment have
                        been largely generalized and lack empirical validation.
                        The challenge becomes even more complex when considering
                        binaural beats, as the range of possible combinations of
                        carrier frequencies and targeted brain wave bands is
                        vast; exceeding one million potential
                        combinations.&nbsp;
                        <Link
                          // onClick={() =>
                          //   onOpenModal(
                          //     SyncBySonicSoundHomeopathybyEvanSoundwell
                          //   )
                          // }
                          target="/"
                          href="https://api.syncbysonic.com/sonicassets/SyncBySonic.pdf"
                        >
                          Read Full Article...
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {file ? (
          <PDFModal open={isOpen} file={file} handleClose={onCloseModal} />
        ) : null}
      </Box>
      <div className="st-height-b120 st-height-lg-b80" />
    </section>
  );
};

export default Overview;
