import React, { useState } from "react";
import FooterLogo from "../../Assets/syncsound/FooterLogo.svg";
import F from "../../Assets/syncsound/f.svg";
import T from "../../Assets/syncsound/T.svg";
import L from "../../Assets/syncsound/L.svg";
import I from "../../Assets/syncsound/I.svg";
import { Divider, Typography } from "@mui/material";
import PDFModal from "./PDFModal";
import PrivacyPolicy from "../../Assets/files/PrivacyPolicy.pdf";
import SyncBySonicSoundHomeopathybyEvanSoundwell from "../../Assets/files/SyncBySonic.pdf";

const Footer = ({ varient }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [file, setFile] = useState(null);

  const onCloseModal = () => {
    setIsOpen(false);
  };
  const onOpenModal = (file) => {
    setFile(file);
    setIsOpen(true);
  };
  return (
    <footer
      className={`st-site-footer st-sticky-footer st-dynamic-bg ${
        varient ? varient : ""
      }`}
      style={{ background: "#162644" }}
    >
      <div className="st-main-footer">
        <div className="container">
          <div className="row">
            <div className="st-footer-widget">
              <div className="st-text-field">
                <div className="row">
                  <div className="col-md-6 social">
                    <img
                      src={FooterLogo}
                      alt="image"
                      className="st-footer-logo"
                    />
                  </div>
                  <div className="col-md-6 d-flex justify-content-md-end gap-3">
                    <a
                      target="/"
                      href="https://api.syncbysonic.com/sonicassets/PrivacyPolicy.pdf"
                    >
                      <Typography
                        fontSize={"16px"}
                        fontWeight={700}
                        color={"#FFFFFF"}
                        // onClick={() => onOpenModal(PrivacyPolicy)}
                      >
                        Privacy policy
                      </Typography>
                    </a>
                    <a
                      target="/"
                      // href="https://api.syncbysonic.com/sonicassets/PrivacyPolicy.pdf"
                    >
                      <Typography
                        fontSize={"16px"}
                        fontWeight={700}
                        color={"#FFFFFF"}
                        // onClick={() =>
                        //   onOpenModal(SyncBySonicSoundHomeopathybyEvanSoundwell)
                        // }
                      >
                        Terms & Conditions
                      </Typography>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <Divider
              sx={{
                background: "#FFFFFF33",
                width: "98.5%",
                display: "flex",
                margin: "auto",
                marginBottom: "50px",
              }}
            />
            <div className="st-footer-widget">
              <div className="st-text-field">
                <div className="row">
                  <div className="col-md-6 social">
                    <div
                      className="st-footer-contact-list st-mp0"
                      style={{
                        // display: "flex",
                        // justifyContent: "center",
                        margin: "auto",
                        paddingTop: "15px",
                        paddingBottom: "10px",
                      }}
                    >
                      &#169; Copyright 2024, all rights received.
                    </div>
                  </div>
                  <div className="col-md-6 d-flex justify-content-md-end gap-3">
                    <a
                      // href="https://www.facebook.com/profile.php?id=61556427729473"
                      className="social-icon"
                    >
                      <img src={F} />
                    </a>
                    <a
                      // href="https://twitter.com/PalFlock"
                      className="social-icon"
                    >
                      <img src={T} />
                    </a>
                    {/* <a href="#" className="social-icon">
                      <img src={L} />
                    </a> */}
                    <a
                      // href="https://www.instagram.com/palflock?igsh=bzA4Zmcyd3phM2Fk&utm_source=qr"
                      className="social-icon"
                    >
                      <img src={I} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {file ? (
          <PDFModal open={isOpen} file={file} handleClose={onCloseModal} />
        ) : null}
      </div>
    </footer>
  );
};
export default Footer;
