import React from "react";
import Footer from "../Footer/Footer";
import { Outlet } from "react-router-dom";
import Header from "../Header/Header";

const headerData = {
  logo: "/images/logo.png",
};

const footerData = {
  logo: "/images/footer-logo.png",
  bgImg: "/images/footer-bg.png",
  subTitle:
    " Lorem ipsum dolor sit consectet adipisicing sed do eiusmod temp incididunt ut labore. Lorem Ipsum is simply dummy.",
};
const Layout = () => {
  return (
    <>
      <>
        <Header data={headerData} />
        <Outlet />
        <Footer data={footerData} />
      </>
    </>
  );
};

export default Layout;
