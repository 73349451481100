import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import HeroMobile from "../../Assets/syncsound/HeroMobile.svg";
import playStore from "../../Assets/syncsound/playStore.svg";
import appStore from "../../Assets/syncsound/appStore.svg";

const Hero = () => {
  return (
    <div id="home" className="hero-section">
      <div className="container">
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <Typography
              textAlign={"left"}
              color={"#162644"}
              fontSize={["28px", "48px"]}
              fontWeight={700}
              fontFamily={"Nunito"}
              lineHeight={["30px", "62.4px"]}
              mb={"15px"}
            >
              Introducing World's First sound homeopathy.
            </Typography>
            <Typography
              textAlign={"left"}
              color={"#162644B2"}
              fontSize={["16px", "20px"]}
              fontWeight={500}
              fontFamily={"Nunito"}
              lineHeight={["20px", "30px"]}
            >
              In the evolving landscape of personalized well-being and
              neuroscience, I am excited to introduce SyncBySonic, a
              groundbreaking application that offers real-time, personalized
              sonic therapy sessions. This innovation is designed to navigate
              the intricate interplay between an individual's unique brainwave
              patterns, the nuances of their voice, and auditory stimuli, such
              as binaural and monaural beats. It is tailored to each person's
              perception of pain, as well as aiming to enhance our scientific
              understanding of brain entrainment techniques in pain management.
            </Typography>
            <Box mt={"30px"} className="st-hero-btn">
              <a
                target="/"
                href="https://play.google.com/store/apps/details?id=com.Syncbysonic"
              >
                <img
                  src={playStore}
                  alt="image"
                  style={{ marginRight: "20px" }}
                  className="playstoreRes"
                />
              </a>
              {/* <a
                target="/"
                // href="https://play.google.com/store/apps/details?id=com.Syncbysonic"
              >
                <img src={appStore} alt="image" className="appStore" />
              </a> */}
            </Box>
          </Grid>
          <Grid item xs={6} display={["none", "none", "none", "block"]}>
            <img src={HeroMobile} alt="Mobile" />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Hero;
