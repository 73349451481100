import React from "react";
import { Box, Typography } from "@mui/material";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
const Feedback = () => {
  const [expanded, setExpanded] = React.useState(false);
  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };
  return (
    <section
      id="faqs"
      className="st-shape-wrap"
      style={{
        background:
          "linear-gradient(293.45deg, #E4EFFF 29.18%, #E6F9FF 58.56%)",
        height: "815px",
      }}
    >
      <Typography
        textAlign={["center", "center"]}
        color={"#162644"}
        fontSize={["28px", "48px"]}
        fontWeight={700}
        fontFamily={"Nunito"}
        lineHeight={["30px", "62.4px"]}
        pt={"75px"}
        pb={"20px"}
      >
        Feedback Coming Soon: Your Experience Matters
      </Typography>
      <div className="container">
        <div className="swiper-part">
          <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
            <SwiperSlide style={{ height: "520px" }}>
              {/* <img src={TestimonialImg} className="image-slider-res" /> */}
              <Box
                width={"100%"}
                margin={"80px 50px"}
                display={"flex"}
                style={{ background: "white" }}
              >
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  style={{ background: "#D0DEF6" }}
                  margin={"30px"}
                  padding={"10px"}
                >
                  Visuals Coming Soon
                </Box>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  margin={"30px"}
                  padding={"10px"}
                >
                  Feedback Coming Soon: Your Experience Matters
                </Box>
              </Box>
            </SwiperSlide>
            <SwiperSlide>
              <Box
                width={"100%"}
                margin={"80px 50px"}
                display={"flex"}
                style={{ background: "white" }}
              >
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  style={{ background: "#D0DEF6" }}
                  margin={"30px"}
                  padding={"10px"}
                >
                  Visuals Coming Soon
                </Box>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  margin={"30px"}
                  padding={"10px"}
                >
                  Feedback Coming Soon: Your Experience Matters
                </Box>
              </Box>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </section>
  );
};
export default Feedback;
