import { Box, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

const PDFModal = ({ open, handleClose, file }) => {
  const docs = [{ uri: file }];
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "80%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {/* <div style={{ width: "100%", height: "500px" }}> */}
        {/* <iframe src={file} frameborder="0" height="100%" width="100%"></iframe> */}
        {/* </div> */}
        <DocViewer
          documents={docs}
          initialActiveDocument={docs[1]}
          pluginRenderers={DocViewerRenderers}
        />
      </Box>
    </Modal>
  );
};

export default PDFModal;
