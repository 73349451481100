import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./sass/style.scss";
import "./sass/custom.scss";
import { SnackbarProvider } from "notistack";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <SnackbarProvider maxSnack={1} preventDuplicate autoHideDuration={1500}>
    <React.StrictMode>
      <BrowserRouter basename={process.env.REACT_APP_HOME_PAGE_URL}>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </SnackbarProvider>
);
