import React, { useRef, useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useSnackbar } from "notistack";

const ContactUs = () => {
  // Contact Form submission
  const form = useRef();
  const { enqueueSnackbar } = useSnackbar();

  const [formData, setFormData] = useState({
    subject: "",
    email: "",
    full_name: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState({
    subject: "",
    email: "",
    full_name: "",
    message: "",
  });

  const validateForm = () => {
    const errors = {};

    if (!formData.subject.trim()) {
      errors.subject = "Subject is required!";
    }

    if (!formData.email.trim()) {
      errors.email = "Email is required!";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      errors.email = "Invalid email address";
    }

    if (!formData.full_name.trim()) {
      errors.full_name = "Full Name is required!";
    }

    if (!formData.message.trim()) {
      errors.message = "Message is required!";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (validateForm()) {
      const recipient = "recipient@example.com"; // Replace with your recipient email
      const subject = ` ${formData.subject}`;
      const body = `Name: ${formData.full_name}\nEmail: ${formData.email}\n\n${formData.message}`;

      const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(
        subject
      )}&body=${encodeURIComponent(body)}`;

      window.location.href = mailtoLink;

      setFormData({
        subject: "",
        email: "",
        full_name: "",
        message: "",
      });

      enqueueSnackbar("Opening email client...", {
        variant: "info",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setFormErrors({
      ...formErrors,
      [name]: "",
    });
  };

  return (
    <section id="contactus">
      <Typography
        textAlign={["center", "center"]}
        color={"#162644"}
        fontSize={["28px", "48px"]}
        fontWeight={700}
        fontFamily={"Nunito"}
        lineHeight={["30px", "62.4px"]}
        pt={"75px"}
        pb={"20px"}
      >
        Contact Us
      </Typography>
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <div className="accordian-res">
              <form onSubmit={sendEmail}>
                <div className="first-3-input">
                  <Box marginTop="25px">
                    <Typography
                      fontSize={"18px"}
                      fontWeight={400}
                      color={"#162644"}
                    >
                      Subject
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      type="text"
                      name="subject"
                      value={formData.subject}
                      onChange={handleChange}
                      error={!!formErrors.subject}
                      helperText={formErrors.subject}
                      placeholder="Enter subject"
                    />
                  </Box>
                  <Box marginTop="25px">
                    <Typography
                      fontSize={"18px"}
                      fontWeight={400}
                      color={"#162644"}
                    >
                      Full name
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      type="text"
                      name="full_name"
                      value={formData.full_name}
                      onChange={handleChange}
                      error={!!formErrors.full_name}
                      helperText={formErrors.full_name}
                      placeholder="Full name"
                    />
                  </Box>
                  <Box marginTop="25px">
                    <Typography
                      fontSize={"18px"}
                      fontWeight={400}
                      color={"#162644"}
                    >
                      Email address
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      error={!!formErrors.email}
                      helperText={formErrors.email}
                      placeholder="Enter Email"
                    />
                  </Box>
                  <Box marginTop="25px">
                    <Typography
                      fontSize={"18px"}
                      fontWeight={400}
                      color={"#162644"}
                    >
                      Message
                    </Typography>
                    <TextField
                      style={{
                        height: "50px",
                      }}
                      multiline
                      rows={10}
                      fullWidth
                      type="text"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      error={!!formErrors.message}
                      helperText={formErrors.message}
                      placeholder="Enter Message"
                    />
                  </Box>
                </div>
                <Button
                  style={{ marginTop: "80px", marginBottom: "50px" }}
                  fullWidth
                  type="submit"
                  variant="contained"
                  sx={{
                    background: "#162644",
                    border: "none",
                    height: "56px",
                    color: "#ffffff",
                    margin: "auto",
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                    "&:hover": {
                      backgroundColor: "#001958",
                      boxShadow: "none",
                    },
                  }}
                >
                  SUBMIT
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
